import React from 'react';
import keyBenefitEasy from '../../images/key_benefit_easy.svg';
import keyBenefitShopping from '../../images/key_benefit_shopping.svg';
import keyBenefitSPricing from '../../images/key_benefit_pricing.svg';

const KeyBenefits = props => {

  return (
    <div className="key-benefits-section">
      <div className="grid__item grid__item--tablet-up-third text-center color-ink">
        <div className="block key-benefit">
          <div className="key-benefit__image-wrap">
            <img src={keyBenefitEasy} className="key-benefit__image" height="240" width="320" alt="" />
          </div>
          <h3 className="block__heading heading--3">
            Easy to use
          </h3>
          <p className="block__content text-major">
          Agents log in to their own account, then switch identities to shop on behalf of a client.
          </p>
        </div>
      </div>
      <div className="grid__item grid__item--tablet-up-third text-center color-ink">
        <div className="block key-benefit">
          <div className="key-benefit__image-wrap">
            <img src={keyBenefitShopping} className="key-benefit__image" height="240" width="320" alt="" />
          </div>
          <h3 className="block__heading heading--3">
            Accountable
          </h3>
          <p className="block__content text-major">
          Control who can order for which clients, from your Shopify dashboard. Keep track of who ordered what for whom.
          </p>
        </div>
      </div>
      <div className="grid__item grid__item--tablet-up-third text-center color-ink">
        <div className="block key-benefit">
          <div className="key-benefit__image-wrap">
            <img src={keyBenefitSPricing} className="key-benefit__image" height="240" width="320" alt="" />
          </div>
          <h3 className="block__heading heading--3">
            Low pricing
          </h3>
          <p className="block__content text-major">
            The Shopify Identity Switcher app is available for $20 per month.
          </p>
        </div>
      </div>
      <hr className="hr grid__ruler" />
    </div>
  )
}

export default KeyBenefits;