import React from 'react';

const Footer = props => {

  return (
    <footer className="footer--main">
      <div className="footer-bottom">
        <div className="grid">
          <div className="grid__item grid__item--tablet-up-2 text-center--mobile">
            <a href="https://code.nl" className="footer-code-logo" target="_blank" rel="noopener noreferrer">
              <svg className="icon" role="img">
                <use xlinkHref="#code-logo-footer"></use>
              </svg>
            </a>
          </div>
          <div className="grid__item grid__item--tablet-up-2 footer-bottom__social text-center">
            <a className="footer-social__icon" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/code.nl">
              <svg className="icon" role="img">
                <title id="icon-modules-social-instagram-99-title">Instagram</title>
                <use xlinkHref="#modules-social-instagram"></use>
              </svg>
            </a>
            <a className="footer-social__icon" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/code.nl">
              <svg className="icon" role="img">
                <title id="icon-modules-social-facebook-96-title">Facebook</title>
                <use xlinkHref="#modules-social-facebook"></use>
              </svg>
            </a>
            <a className="footer-social__icon" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/code-internet-applications">
              <svg className="icon" role="img">
                <title id="icon-modules-social-linkedin-100-title">LinkedIn</title> 
                <use xlinkHref="#modules-social-linkedin"></use>
              </svg>
            </a>
            <a className="footer-social__icon" target="_blank" rel="noopener noreferrer" href="https://twitter.com/code_nl">
              <svg className="icon" role="img">
                <title id="icon-modules-social-twitter-97-title">Twitter</title>
                <use xlinkHref="#modules-social-twitter"></use>
              </svg>
            </a>
          </div>
          <div className="grid__item grid__item--tablet-up-2 footer-bottom__links text-center--mobile text-right--tablet-up">
            <small>&copy; Copyright 2019, <a href="https://www.code.nl" title="CODE" target="_blank" rel="noopener noreferrer">CODE</a> </small>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;