import React from 'react';
import Header from './modules/app-store-components/Header';
import Hero from './modules/app-store-components/Hero';
import KeyBenefits from './modules/app-store-components/KeyBenefits';
import About from './modules/app-store-components/About';
import MediaGallery from './modules/app-store-components/MediaGallery';
import Support from './modules/app-store-components/Support';
import Pricing from './modules/app-store-components/Pricing';
import AddApp from './modules/app-store-components/AddApp';
import Footer from './modules/app-store-components/Footer';
import IconDefs from './modules/app-store-components/IconDefs';

const AppStorePage = props => {

  return (
    <div>
      <IconDefs />
      <Header />
      <div className="page-wrap">
        <main>
          <Hero />
          <section className="section">
            <div className="grid">
              <KeyBenefits />

              <div className="grid__item grid__item--tablet-up-two-thirds grid__item--desktop-up-6 app-listing-description">
                <About />

                <div className="block app-listing__section">
                  <h3 className="block__heading heading--3">Media gallery</h3>
                  <div className="block__content">
                    <MediaGallery />
                  </div>
                </div>
              </div>

              <div className="grid__item grid__item--tablet-up-third grid__item--desktop-up-3 grid__item--desktop-up-push-3">
                <Support />
              </div>
            </div>

            <Pricing />
          </section>
          
          <AddApp />
        </main>

        <Footer />
      </div>
    </div>
  );
}

export default AppStorePage;