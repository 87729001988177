import React from 'react';

const Support = props => {

  return (
    <div className="ui-app-store-hero__cta">
      <div className="block app-listing__support-section app-listing__section">
        <h3 className="block__heading heading--2">Support</h3>
        <div className="block__content">
          <ul className="app-support-list">
            {/* <li className="app-support-list__item">
              <span>
                <svg className="icon" aria-hidden="true" focusable="false">
                  <use xlinkHref="#v2-icons-icon-faq"></use>
                </svg>
                <a className="ui-external-link" href="https://code.nl" target="_blank" rel="noopener noreferrer" aria-describedby="aria-new-window-desc">
                  FAQ
                  <svg className="icon" aria-hidden="true" focusable="false">
                    <use xlinkHref="#polaris-external"></use>
                  </svg>
                </a>
              </span>
            </li> */}
            <li className="app-support-list__item">
              <span>
                <svg className="icon" aria-hidden="true" focusable="false">
                  <use xlinkHref="#v2-icons-icon-website-url"></use>
                </svg>
                <a className="ui-external-link" href="https://code.nl/en/contact" target="_blank" rel="noopener noreferrer" aria-describedby="aria-new-window-desc">
                  Developer website
                  <svg className="icon" aria-hidden="true" focusable="false"> 
                    <use xlinkHref="#polaris-external"></use>
                  </svg>
                </a>
              </span>
            </li>
            {/* <li className="app-support-list__item">
              <span>
                <svg className="icon" aria-hidden="true" focusable="false">
                  <use xlinkHref="#v2-icons-icon-privacy"></use>
                </svg>
                <a className="ui-external-link" href="https://code.nl" target="_blank" rel="noopener noreferrer" aria-describedby="aria-new-window-desc">
                  Privacy policy
                  <svg className="icon" aria-hidden="true" focusable="false">
                    <use xlinkHref="#polaris-external"></use>
                  </svg>
                </a>
              </span>
            </li> */}
            <li className="app-support-list__item">
              <span>
                <svg className="icon" aria-hidden="true" focusable="false">
                  <use xlinkHref="#v2-icons-icon-email"></use>
                </svg>support@code.nl
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Support;