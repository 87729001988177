// import './modules/components/bootstrap';

// --- Post bootstrap -----
import React from 'react';
//import Home from './Home';
import AppStorePage from './AppStorePage';

const App = props => {
  //return <Home />;
  return <AppStorePage />;
}

export default App;
